import request from '@/plugins/axios'

export function authLogin(data) {
  return request({
    url: `/auth/restLogin?site=${data.origin}&token=${data.auth}`,
    method: 'get'
  })
}

export function logOut() {
  return request({
    url: '/auth/logout',
    method: 'get'
  })
}

export function loginUserInfo() {
  return request({
    url: '/user/getUserInfo',
    method: 'get'
  })
}
