import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
// import Layout from '../layout/Stage'
import Layout from '@/layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/index',
    name: 'indexParent',
    children: [
      {
        path: 'index',
        name: 'index',
        component: () => import('@/views/index/Index'),
        meta: { title: '首页', icon: 'el-icon-house' }
      }
    ]
  },
  {
    path: '/key',
    component: Layout,
    name: 'keyParent',
    meta: {
      title: '方案管理',
      icon: 'el-icon-search'
    },
    children: [
      {
        path: 'words',
        name: 'keywords',
        component: () => import('@/views/keyWord/Keywords.vue'),
        meta: {
          title: '关键词方案',
          icon: 'el-icon-s-comment'
        }
      },
      {
        path: 'channel',
        name: 'channel',
        component: () => import('@/views/keyWord/Channel.vue'),
        meta: {
          title: '频道方案',
          icon: 'el-icon-magic-stick'
        }
      },
      {
        path: '/video/list/:channelId',
        name: 'videoList',
        component: () => import('@/views/keyWord/ChannelVideo.vue'),
        hidden: true,
        meta: {
          title: '视频列表',
          icon: 'el-icon-magic-stick'
        }
      },
      {
        path: '/video/detail/:videoId',
        name: 'videodetail',
        component: () => import('@/views/keyWord/VideoDetail.vue'),
        hidden: true,
        meta: {
          title: '视频详情',
          icon: 'el-icon-magic-stick'
        }
      }
    ]
  },

  {
    path: '/retrieval',
    component: Layout,
    name: 'retrieval',
    meta: {
      title: '数据检索',
      icon: 'el-icon-mouse'
    },
    children: [
      {
        path: 'video',
        name: 'retrievalVideo',
        component: () => import('@/views/retrieval/Video.vue'),
        meta: {
          title: '视频数据',
          icon: 'el-icon-video-play'
        }
      },
      {
        path: 'comment',
        name: 'retrievalComment',
        component: () => import('@/views/retrieval/Comment.vue'),
        meta: {
          title: '评论数据',
          icon: 'el-icon-document'
        }
      }
    ]
  },

  {
    path: '/collection',
    component: Layout,
    name: 'collection',
    children: [
      {
        path: 'list',
        name: 'collectionList',
        component: () => import('@/views/collection/Collection.vue'),
        meta: {
          title: '我的收藏',
          icon: 'el-icon-star-on'
        }
      }
    ]
  },

  {
    path: '/authLogin',
    name: 'AuthLogin',
    component: () => import('@/views/AuthLogin.vue')
  },
  {
    path: '/version',
    name: 'Version',
    component: () => import('@/views/Version.vue')
  },
  {
    path: '/401',
    name: '401',
    component: () => import('@/views/errorPage/401.vue')
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
